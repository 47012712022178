import { Button, Form, Input } from "antd";
import FloatLabel from "src/components/Form/FloatLabel";
import { IMessageProps, ISendMessageProps } from "./SendMessage.model";
import TextArea from "antd/lib/input/TextArea";

const SendMessage = (props: ISendMessageProps) => {
  const { closeModal, brocherLink } = props;

  const rules = {
    mobileNumber: [
      { required: true, message: "Please Enter Mobile Number" },
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
      {
        min: 10,
        max: 10,
        message: "Mobile Number Must Be 10 Digit Long",
      },
    ],
    message: [{ required: true, message: "Please Enter Message" }],
  };

  return (
    <Form
      className="drawer-form"
      name="basic"
      onFinish={(values: IMessageProps) => {
        const { mobileNumber, message } = values;
        const formattedMessage = `${message}\n\n${brocherLink}`;
        const encodedMessage = encodeURIComponent(formattedMessage);
        const whatsappLink = `https://wa.me/${mobileNumber}?text=${encodedMessage}`;
        window.open(whatsappLink, "_blank");
        closeModal();
      }}
      initialValues={undefined}
      autoComplete="off"
      requiredMark={true}
    >
      <FloatLabel
        label="Mobile Number"
        placeholder="Enter Mobile Number"
        name="mobileNumber"
        required
      >
        <Form.Item name="mobileNumber" rules={rules.mobileNumber}>
          <Input size="large" />
        </Form.Item>
      </FloatLabel>

      <FloatLabel
        label="Message"
        placeholder="Enter Message"
        name="message"
        required
      >
        <Form.Item name="message" rules={rules.message}>
          <TextArea style={{ height: 100 }} />
        </Form.Item>
      </FloatLabel>

      <Form.Item className="modal-btn-grp">
        <Button type="primary" htmlType="submit" className="btn-submit">
          Send
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SendMessage;
