import {
  Col,
  Input,
  Row,
  Form,
  Select,
  DatePicker,
  Card,
  Button,
  message,
  Radio,
  Typography,
} from "antd";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ColumnsType } from "antd/lib/table";
import TableComponent from "src/components/DataTable";
import FloatLabel from "src/components/Form/FloatLabel";
import {
  IFaculty,
  ISaveInterviewSessionPayload,
} from "src/services/students/student.model";
import { useAppSelector } from "src/state/app.hooks";
import {
  clearRemoveMessage,
  studentsSelector,
} from "src/state/students/students.reducer";
import StudentMobileNumber from "src/components/StudentMobileNumber";

import { userSelector } from "src/state/users/user.reducer";
import {
  AdmisionStatusType,
  Admission_Recurring_type,
  AdmissionSubcourseStatus,
  ZoneType,
} from "src/utils/constants/constant";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  updateInterviewSessionData,
  saveInterviewSessionData,
  getInterviewSessionData,
  getPisAdmissionRecurringData,
} from "src/state/students/students.action";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import moment from "moment";
import { useWatch } from "antd/lib/form/Form";
import {
  facultyDataByBranchId,
  getFacultyByZone,
} from "src/utils/helperFunction";
import {
  ICompanyVisitFieldProps,
  IFacultyData,
} from "src/pages/CompanyVisitUpdateCard/CompanyVisit.model";
import { branchSelector } from "src/state/branch/branch.reducer";
import { userBatchSelector } from "src/state/userBatch/userBatch.reducer";
import { MasterDahsboardFilterContext } from "src/contexts/MasterDashboardFilterContext";
const { Text } = Typography;
const { Option } = Select;

function ReportInterviewSessionField(props: ICompanyVisitFieldProps) {
  const {
    data,
    form,
    myRef,
    isDrawer,
    selectBatchField,
    isDisabled,
    selectBranch,
    setSelectBranch,
    batchIds,
    setShow,
    isEdit,
    isBatch,
    batchStudentList,
    resetSelectBatch,
  } = props;
  const dateFormat = "DD/MM/YYYY";
  const { id } = useParams();
  const userState = useAppSelector(userSelector);
  const studentState = useAppSelector(studentsSelector);
  const editInterviewSessionData =
    studentState.interviewSessionData?.data?.rows.find(
      (pis) => pis.id === Number(id)
    );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multiId, setMultiId] = useState<number>(0);
  const [studentDetail, setStudentDetail] = useState<number[]>([]);
  const { TextArea } = Input;
  const dispatch = useDispatch<AppDispatch>();
  const storageID: string | null = localStorage.getItem("myStorageID");
  const { userData } = useAppSelector(userSelector);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const branchState = useAppSelector(branchSelector);
  const userBatchState = useAppSelector(userBatchSelector);
  const { masterSelectedZone } = useContext(MasterDahsboardFilterContext);
  const rules = {
    interviewer_name: [
      { required: true, message: "Please Enter Interviewer Name" },
      { max: 200, message: "Max 200 character long" },
    ],
    venue: [
      { required: true, message: "Please Enter Venue" },
      { max: 200, message: "Max 200 character long" },
    ],
    subject: [
      { required: true, message: "Please Enter Subject" },
      { max: 200, message: "Max 200 character long" },
    ],
    venue_datetime: [{ required: true, message: "Please Enter Venue Date" }],
    execute_by_user_id: [
      { required: true, message: "Please Select Created By" },
    ],
    branch_id: [{ required: true, message: "Please Select BranchId" }],
  };
  const dataView = useWatch("venue_datetime", form);

  const onReset = () => {
    setSelectedRowKeys([]);
    setTimeout(() => form.resetFields());
    setMultiId(0);
  };

  const values =
    {
      ...editInterviewSessionData,
      volunteers:
        editInterviewSessionData?.interview_volunteers?.map((obj) =>
          Number(obj.user_id)
        ) || [],
      venue_datetime: moment(editInterviewSessionData?.venue_datetime),
      branch_id: editInterviewSessionData?.branch?.id,
      execute_by_user_id: Number(
        editInterviewSessionData?.execute_by_user?.id || 0
      ),
    } || {};

  const studentListData =
    studentState.pisAdmissionRecurringData?.data?.rows.filter(
      (d) =>
        d.type === Admission_Recurring_type.PIS &&
        (isEdit
          ? d.type_id === Number(id) || d.type_id == null
          : d.type_id == null)
    );
  useEffect(() => {
    if (isEdit && editInterviewSessionData === undefined) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (isEdit && editInterviewSessionData?.admission_recurings) {
      const studentList = editInterviewSessionData.admission_recurings.map(
        (data) => Number(data.id)
      );
      setSelectedRowKeys(studentList as never[]);
      setStudentDetail(studentList);
    } else {
      form.setFieldValue("is_online", false);
      setStudentDetail([]);
    }
  }, []);

  useEffect(() => {
    if (studentState.saveInterviewSession.message != "") {
      if (studentState.saveInterviewSession.hasErrors) {
        message.error(studentState.saveInterviewSession.message);
      } else {
        message.success(studentState.saveInterviewSession.message);
      }
    }
    dispatch(clearRemoveMessage());
  }, [studentState.saveInterviewSession]);

  useEffect(() => {
    if (studentState.updateInterviewSession.message != "") {
      if (studentState.updateInterviewSession.hasErrors) {
        message.error(studentState.updateInterviewSession.message);
      } else {
        message.success(studentState.updateInterviewSession.message);
      }
    }
    dispatch(clearRemoveMessage());
  }, [studentState.updateInterviewSession]);

  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;

  useEffect(() => {
    if (dataView === null) {
      form.setFieldValue("venue_datetime", undefined);
    }
  }, [dataView]);
  const facultyData = currentUserZone
    ? getFacultyByZone(
        userState.usersData.data,
        currentUserZone?.id,
        [...values.volunteers, values.execute_by_user_id] || []
      )
    : [];
  const zoneDataForFaculty = useMemo(() => {
    return (
      masterSelectedZone.length > 0
        ? masterSelectedZone
        : currentUserZone?.type === ZoneType.PUBLIC
    )
      ? []
      : [Number(currentUserZone?.id) || 0];
  }, [masterSelectedZone, currentUserZone]);

  const branchId = useWatch("branch_id", form);
  const facultyDataByZone = useMemo(() => {
    return (
      facultyDataByBranchId(
        branchState.branchesData.data.rows
          .filter((branch) => (branchId ? branchId === branch.id : branch))
          .map((branch) => branch.id),
        userState.usersData.data,
        branchState.branchesData.data,
        zoneDataForFaculty
      ) || []
    );
  }, [
    branchState.branchesData.data,
    userState.usersData.data,
    branchId,
    zoneDataForFaculty,
  ]);

  const onSelectChange = (newSelectedRowKeys: []) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setStudentDetail(newSelectedRowKeys);
    if (newSelectedRowKeys.length > 0) {
      setMultiId(newSelectedRowKeys.length);
    } else {
      setMultiId(0);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onFinish = async (values: ISaveInterviewSessionPayload) => {
    if (disableSubmitBtn) {
      setDisableSubmitBtn(false);
      const finalData = {
        ...values,
        interviewer_name: values?.interviewer_name?.replaceAll(/'/g, "''"),
        subject: values.subject?.replaceAll(/'/g, "''"),
        is_online: values.is_online ? values.is_online : false,
        venue: values.venue?.replaceAll(/'/g, "''"),
        details: values.details?.replaceAll(/'/g, "''"),
        status: AdmisionStatusType.PENDING,
        admission_recurings: studentDetail as number[],
        branch_id: values.branch_id
          ? values.branch_id
          : Number(
              currentUserZone?.branches && currentUserZone?.branches?.length > 0
                ? currentUserZone?.branches[0].id
                : 0
            ),
        isBatch: isBatch ? true : false,
      };
      if (studentDetail.length > 0) {
        (isEdit && id
          ? dispatch(
              updateInterviewSessionData({ id: Number(id), ...finalData })
            )
          : dispatch(saveInterviewSessionData(finalData))
        ).then((res) => {
          if (res.payload) {
            dispatch(getInterviewSessionData());
            if (studentState.pisAdmissionRecurringData.loading === false) {
              dispatch(
                getPisAdmissionRecurringData({
                  ...searchParams,
                  order: "ASC",
                  orderBy: "end_date",
                  noLimit: true,
                })
              );
            }
            setStudentDetail([]);
            setShow && setShow(false);
            resetSelectBatch && resetSelectBatch();
            onReset();
            if (isEdit) {
              navigate("/");
            }
          }
        });
      } else {
        setDisableSubmitBtn(true);
        message.error("Student Detail is required");
      }
    }
  };

  const studentList: ColumnsType<[]> = [
    {
      title: "Sr No",
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "GR ID",
      render: (record) => {
        return <span>{record?.admission?.gr_id}</span>;
      },
    },
    {
      title: "Student Name",
      render: (record) => {
        return (
          <span>
            {record?.admission?.first_name} {record?.admission?.last_name}
            <br />
            <StudentMobileNumber
              studentMobileNumber={record?.admission?.mobile_no}
            />
          </span>
        );
      },
    },
    {
      title: "Father Name",
      render: (record) => {
        const fatherNo = record?.admission?.father_mobile_no;
        return (
          <span>
            {record?.admission?.father_name || record?.admission?.middle_name}{" "}
            <br />
            {fatherNo !== null && (
              <StudentMobileNumber studentMobileNumber={fatherNo} />
            )}
          </span>
        );
      },
    },
    {
      title: "Email",
      render: (record) => {
        return <span>{record?.admission?.email}</span>;
      },
    },
  ];

  const Options = facultyDataByZone.map((fd) => {
    return (
      <Option value={fd?.id}>{`${fd?.first_name}  ${fd?.last_name}`}</Option>
    );
  });

  const setDefaultBranchAndBatchValue = () => {
    form.setFieldsValue({
      ...form,
      branch_id: selectBranch,
      batch_name: findBatchesNameOverSelectedBatches()?.map(
        (tag: { id: number }) => tag.id
      ),
    });
  };

  const findSelectedBranchStudentsList = () => {
    return (
      (isBatch
        ? selectBranch
          ? batchStudentList?.filter(
              (studentList) => studentList.admission.branch_id === selectBranch
            )
          : []
        : studentListData) ?? []
    );
  };

  const findBatchesNameOverSelectedBatches = () => {
    return userBatchState.searchMyTeamBatchesList.data.rows
      .filter((res) => batchIds?.includes(res.id))
      .map((res) => ({ id: res.id, name: res.name }));
  };

  return (
    <div>
      {" "}
      <Form
        className="drawer-form"
        name="dynamic_form_nest_item"
        form={form}
        initialValues={
          isEdit
            ? values
            : { ...data, batch_name: setDefaultBranchAndBatchValue() }
        }
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        requiredMark={true}
      >
        {" "}
        <Row gutter={8}>
          <Col span={24}>
            <FloatLabel
              label="Interviewer Name"
              placeholder="Enter Interviewer Name"
              name="interviewer_name"
              required
            >
              <Form.Item name="interviewer_name" rules={rules.interviewer_name}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Venue"
              placeholder="Enter Venue"
              name="venue"
              required
            >
              <Form.Item name="venue" rules={rules.venue}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <Text strong style={{ display: "block" }}>
              Is Online<span className="gx-ml-1 gx-text-danger">*</span>
            </Text>
            <Form.Item name="is_online">
              <Radio.Group defaultValue={false}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {selectBatchField && currentUserZone?.type !== ZoneType.PRIVATE && (
            <Col span={24}>
              <FloatLabel
                label="Batch Name"
                placeholder="Select Batch Name"
                name="batch_name"
              >
                <Form.Item name="batch_name">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    showSearch
                    showArrow
                    disabled
                    mode="multiple"
                    filterOption={(input, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(input.toLowerCase().trim())
                    }
                    size="large"
                  >
                    {findBatchesNameOverSelectedBatches().map((tag) => (
                      <Option value={tag.id}>{tag.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          )}
          <Col span={24}>
            {currentUserZone?.type !== ZoneType.PRIVATE && (
              <FloatLabel
                label="Branch"
                placeholder="Select Branch"
                name="branch_id"
                required
              >
                <Form.Item name="branch_id" rules={rules.branch_id}>
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    showSearch
                    disabled={isDisabled}
                    showArrow
                    size="large"
                    // className="gx-mr-2"
                    filterOption={(value, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                    onChange={(value) => {
                      setSelectBranch && setSelectBranch(value);
                    }}
                  >
                    {branchState.branchesData.data.rows
                      .filter((branch) => branch.status === true)
                      .map((branch) => (
                        <Option value={branch.id}>{branch.code}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            )}
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Subject"
              placeholder="Enter subject"
              name="subject"
              required
            >
              <Form.Item name="subject" rules={rules.subject}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Venue Date"
              placeholder="Select Date"
              name="venue_datetime"
              required
            >
              <Form.Item name="venue_datetime" rules={rules.venue_datetime}>
                <DatePicker
                  size="large"
                  placeholder=""
                  style={{ width: "100%" }}
                  format={dateFormat}
                />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Detail"
              placeholder="Enter Detail"
              name="details"
            >
              <Form.Item name="details">
                <TextArea rows={4} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Created By"
              placeholder="Select Created By"
              name="execute_by_user_id"
              required
            >
              <Form.Item
                name="execute_by_user_id"
                rules={rules.execute_by_user_id}
              >
                <Select allowClear size="large">
                  {Options}
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Attendance By"
              placeholder="Select Attendance"
              name="volunteers"
            >
              <Form.Item name="volunteers">
                <Select allowClear showArrow mode="multiple" size="large">
                  {Options}
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <Card className="faculty-dashboard-card gx-mt-2">
              <div className="gx-d-flex gx-justify-content-between">
                <h3>
                  Student Detail ({findSelectedBranchStudentsList().length})
                </h3>
                {multiId > 0 && (
                  <div className="gx-text-right">
                    Total Selected Student{" "}
                    <span className="selected-stud">({multiId})</span>
                  </div>
                )}
              </div>
              <div className="card-body faculty-dashboard company-visit-card student-detail-dashboard">
                <TableComponent
                  rowSelection={rowSelection}
                  columns={studentList}
                  rowKey={(record) =>
                    isBatch ? record.admission.id : record.id
                  }
                  dataSource={findSelectedBranchStudentsList()}
                  loading={false}
                  rowClassName={(record) => {
                    if (isBatch) {
                      const status = record.subcourse_status;
                      return status === AdmissionSubcourseStatus.COMPLETED
                        ? "completed "
                        : "";
                    } else {
                      return "";
                    }
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {!isDrawer ? (
          <div className="gx-mt-3 gx-d-flex gx-justify-content-lg-end footer-button">
            <Button type="primary" htmlType="submit" className="gx-my-0">
              Update
            </Button>
            <Button className="btn-cancel" onClick={() => navigate("/")}>
              Cancel
            </Button>
          </div>
        ) : (
          <Form.Item className="modal-btn-grp" style={{ display: "none" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              ref={myRef}
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

export default ReportInterviewSessionField;
