import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
} from "antd";
import { FileTextOutlined, DownloadOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ContainerHeader from "src/components/ContainerHeader";
import TableComponent from "src/components/DataTable";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import {
  getActiveStudentList,
  sendBranchActiveStudentListCsv,
} from "src/state/report/report.action";
import { reportSelector, clearRemoveMessage } from "src/state/report/report.reducer";
import {
  IActiveStudentList,
  IActiveStudentListCsv,
} from "./ActiveStudentList.model";
import { IActiveStudentListReport } from "src/services/report/report.model";
import { DEFAULT_DATE_FORMAT, ZoneType } from "src/utils/constants/constant";
import { userSelector } from "src/state/users/user.reducer";
import { dateFormateWithDDMMYYYY } from "src/utils/helperFunction";
function ActiveStudentList() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [formInitialData, setFormInitialData] = useState({});
  const branchState = useAppSelector(branchSelector);
  const reportState = useAppSelector(reportSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const { userData } = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const rules = {
    active_date: [{ required: true, message: "Please Select date" }],
    branch_ids: [{ required: true, message: "Please Select Branch" }],
  };

  useEffect(() => {
    if (branchState.branchesData.data) {
      dispatch(
        searchBranchData({
          noLimit: true,
          orderBy: "name",
          order: "ASC",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (reportState.activeStudentDaysCvData.message) {
      if (reportState.activeStudentDaysCvData.hasErrors) {
        message.error(reportState.activeStudentDaysCvData.message);
      } else {
        message.success(reportState.activeStudentDaysCvData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [reportState.activeStudentDaysCvData.message]);

  const onFinish = (values: IActiveStudentList) => {
    const newData = {
      branch_ids: currentUserZone?.type !== ZoneType.PRIVATE ? values.branch_ids.join(",") : currentUserZone?.branches?.map((record)=>record.id).join(","),
      start_date: values.active_date[0].format(dateFormat),
      end_date: values.active_date[1].format(dateFormat),
    };

    dispatch(getActiveStudentList(newData))
  };

  const handleExpensesCsv = (paramsData: IActiveStudentListCsv) => {
    dispatch(sendBranchActiveStudentListCsv(paramsData));
  };

  const columns: ColumnsType<IActiveStudentListReport> = [
    {
      title: "No.",
      dataIndex: "id",
      sorter: false,
      align: "center",
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      className: "branch_name",
      align: "center",
    },
    {
      title: "Start Date - End Date",
      dataIndex: "start_date",
      className: "active_date",
      align: "center",
      render: (_, record) => (
        <>
          {dateFormateWithDDMMYYYY(record.start_date)} -{" "}
          {dateFormateWithDDMMYYYY(record.end_date)}
        </>
      ),
    },
    {
      title: "Active Days",
      dataIndex: "active_days",
      className: "active_days",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <>
          <Button
            icon={<FileTextOutlined />}
            onClick={() => {
              Modal.confirm({
                title: `Are you sure want to download ${record.branch_name} branchs students CSV file?`,
                okText: "Yes",
                cancelText: "No",
                icon: <DownloadOutlined />,
                onOk() {
                  const paramsData = {
                    branch_ids: record.branch_id,
                    start_date: record.start_date,
                    end_date: record.end_date,
                  };
                  handleExpensesCsv(paramsData);
                },
              });
            }}
          >
            CSV
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="rnw-main-content active-student-list">
        <Skeleton loading={false} active avatar>
          <Row
            align="middle"
            justify="space-between"
            gutter={24}
            className="mb-20 gx-flex-wrap gx-align-items-center gx-justify-content-between gx-pt-1"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={8}
              xxl={8}
              className="gx-mb-md-1"
            >
              <ContainerHeader title="Active Student List" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <Form
                id="myForm"
                form={form}
                onFinish={onFinish}
                initialValues={{ ...formInitialData }}
              >
                <Row gutter={[24, 24]} justify={"end"}>
                  <Col
                    xs={24}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={8}
                    xxl={8}
                    className="gx-pr-2"
                  >
                    <Form.Item name="active_date" rules={rules.active_date}>
                      <RangePicker
                        name="active_date"
                        style={{ width: "100%" }}
                        getPopupContainer={(trigger) => trigger}
                        format={DEFAULT_DATE_FORMAT}
                        disabledDate={(current) => current && current > moment()}
                        ranges={{
                          Today: [moment(), moment()],
                          Week: [
                            moment().startOf("week"),
                            moment().endOf("week").isAfter(moment()) ? moment() : moment().endOf("week"),
                          ],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month").isAfter(moment()) ? moment() : moment().endOf("month"),
                          ],
                          "This Year": [
                            moment().startOf("year"),
                            moment().endOf("year").isAfter(moment()) ? moment() : moment().endOf("year"),
                          ],
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {currentUserZone?.type !== ZoneType.PRIVATE && (
                    <Col xs={24} sm={10} md={11} lg={9} xl={8} xxl={8}>
                      <Form.Item name="branch_ids" rules={rules.branch_ids}>
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          allowClear
                          placeholder={"Select Branch"}
                          size="large"
                          mode="multiple"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.children?.toString() || "")
                              .toLowerCase()
                              .includes(input.toLowerCase().trim())
                          }
                          onChange={(selectedValues) => {
                            if (selectedValues.includes("all")) {
                              const allBranchIds =
                                branchState.branchesData.data?.rows
                                  .filter((branch) => branch.status === true)
                                  .map((branch) => branch.id);
                              form.setFieldsValue({
                                branch_ids: allBranchIds,
                              });
                            } else {
                              form.setFieldsValue({
                                branch_ids: selectedValues,
                              });
                            }
                          }}
                        >
                          <Option value="all">All</Option>
                          {branchState.branchesData.data?.rows
                            ?.filter((branch) => branch.status === true)
                            .map((branch) => {
                              return (
                                <Option value={branch.id}>{branch.code}</Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col flex={"90x"} className="csv-download-btn">
                    <Button
                      className="ant-btn"
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      form="myForm"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Card className="rnw-card table-card cheque-amount gx-mb-0">
            <TableComponent
              columns={columns}
              dataSource={reportState.activeStudentDays.data || []}
              loading={false}
            />
          </Card>
        </Skeleton>
      </div>
    </>
  );
}

export default ActiveStudentList;
